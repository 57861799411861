
import draggable from "vuedraggable";
import KanbanItem from "./KanbanItem.vue"

export default {
  components: {
    draggable,
    KanbanItem,
  },

  props: {
    list: {
      type: Array,
      required: true
    },

    status: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      drag: false,
      selected: "",
      selectedId: null,
    };
  },

  methods: {
    onClick(item) {
      this.$emit("item-click", item.id);
    },

    onAdd(e) {
      e.item.style.display = "none";
    },

    onChange({added}) {
      if (!added) {
        return;
      }

      this.$store.dispatch("lists/updateListEntity", {...added.element, type: added.element.__typename, statusId: this.status?.id || null ,newIndex: added.newIndex});

      this.$amplitude.track({ event_type: "Move list item status", event_properties: {
        newStatus: this.status?.name,
          newIndex: added.newIndex,
          bfeNumber: added.element?.bfeNumber,
      }});
    },

    onUpdate({newIndex, item: {_underlying_vm_}}) {
      if(!_underlying_vm_) {
        return;
      }

      this.$store.dispatch("lists/updateListEntity", {..._underlying_vm_, type: _underlying_vm_.__typename, statusId: this.status?.id || null  ,newIndex: newIndex});

      this.$amplitude.track({ event_type: "Move list item priority", event_properties: {
        newStatus: this.status?.name,
          newPriority: newIndex,
          bfeNumber: _underlying_vm_?.bfeNumber,
      }});
    },

    choose(a){
      this.selected = a.item.__vue__?.$attrs?.id
    },

    unchoose(){
      this.selected = "";
    }
  }
};
