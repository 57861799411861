
import ListHeader from "~/graphql/Lists/Collaboration/ListHeader.gql";
import Me from "~/graphql/Authentication/Me.gql";
import { getUsersListPermissions } from "~/helpers/apollo/apollo-user-query-helpers.js";

import ModalExport from "./ModalExport.vue";
import ModalEditList from "./ModalEditList.vue";
import ModalDeleteList from "./ModalDeleteList.vue";

import {
  LIST_PERMISSION_PRIVATE,
  LIST_PERMISSION_USER_DEFINED,
  LIST_PERMISSION_ORGANIZATION,
  USER_PERMISSION_NONE,
  USER_PERMISSION_ADMIN,
  USER_PERMISSION_READ,
} from "./constants.js";

import ChromeTooltip from "../../../../components/Chrome/ChromeTooltip.vue";

export default {
  name: "Header",

  apollo: {
    list: {
      query: ListHeader,

      variables() {
        return {
          input: this.$route.params.id,
        };
      },
    },

    me: {
      query: Me,
    },

    users: getUsersListPermissions,
  },

  components: {
    ModalExport,
    ModalEditList,
    ModalDeleteList,
    ChromeTooltip,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  LIST_PERMISSION_PRIVATE: LIST_PERMISSION_PRIVATE,
  LIST_PERMISSION_USER_DEFINED: LIST_PERMISSION_USER_DEFINED,
  LIST_PERMISSION_ORGANIZATION: LIST_PERMISSION_ORGANIZATION,

  USER_PERMISSION_NONE: USER_PERMISSION_NONE,
  USER_PERMISSION_ADMIN: USER_PERMISSION_ADMIN,
  USER_PERMISSION_READ: USER_PERMISSION_READ,

  data() {
    return {
      exportListModal: false,
      editListModal: false,
      deleteListModal: false,
    };
  },

  computed: {
    _currentView: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    listMetaData() {
      return this.list || {};
    },

    listLoading() {
      return this.$apollo.queries.list.loading;
    },

    usersLoading() {
      return this.$apollo.queries.users.loading;
    },

    usersWithAdminRights() {
      if (this.list && this.list.listUserPermissions) {
        return this.list.listUserPermissions.filter((u) => u.user && u.permission === this.$options.USER_PERMISSION_ADMIN);
      } else {
        return [];
      }
    },

    usersWithReadRights() {
      if (this.users && this.list?.listUserPermissions) {
        return this.list.listUserPermissions.filter(
          (u) => u.user && (u.permission === this.$options.USER_PERMISSION_READ || u.permission === this.$options.USER_PERMISSION_ADMIN)
        );
      } else {
        return [];
      }
    },

    meHasAdminRights() {
      if (this.me && this.users && this.list?.listUserPermissions) {
        return this.list.listUserPermissions.filter((u) => u.userId === this.me.id && u.permission === this.$options.USER_PERMISSION_ADMIN).length > 0;
      } else {
        return false;
      }
    },

    usersOnListMergedWithAllUsers() {
      if (this.users && this.list && this.list?.listUserPermissions) {
        return this.users.map((outer) => {
          const match = this.list.listUserPermissions.find((inner) => outer.id === inner.userId);

          if (match) {
            return {
              id: match.userId,
              name: match.user.name,
              permission: match.permission,
            };
          }

          return {
            id: outer.id,
            name: outer.name,
            permission: this.$options.USER_PERMISSION_NONE,
          };
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    listDeleted() {
      this.deleteListModal = false;
      this.$router.replace("/my-estaid/lists");
    },

    editListModalClosed() {
      this.editListModal = false;
    },

    editListModalSaved() {
      this.editListModal = false;

      setTimeout(() => {
        this.$apollo.queries.list.refetch();
      }, 0);
    },

    getPrivacyLabel(privacy) {
      switch (privacy) {
        case this.$options.LIST_PERMISSION_PRIVATE:
          return this.$t("LISTS_CREATE_ACCESS_ME_LABEL");

        case this.$options.LIST_PERMISSION_USER_DEFINED:
          return this.$t("LISTS_CREATE_ACCESS_USER_DEFINED_LABEL");

        case this.$options.LIST_PERMISSION_ORGANIZATION:
          return this.$t("LISTS_CREATE_ACCESS_WHOLE_ORG_LABEL");
      }
    },

    getUsersLabel(privacy) {
      switch (privacy) {
        case this.$options.LIST_PERMISSION_PRIVATE:
          return this.$t("LISTS_CREATE_ACCESS_ME_LABEL");

        case this.$options.LIST_PERMISSION_USER_DEFINED:
          if (this.usersWithReadRights.length > 0) {
            let label = this.usersWithReadRights.length;
            if (this.usersWithReadRights.length === 1) {
              label += ` ${this.$t("LIST_HEADER_LABEL_USER")}`;
            } else {
              label += ` ${this.$t("LIST_HEADER_LABEL_USER_PLURAL")}`;
            }
            return label;
          } else {
            return this.$t("LIST_HEADER_LABEL_USER_NONE");
          }

        case this.$options.LIST_PERMISSION_ORGANIZATION:
          return this.$t("LISTS_CREATE_ACCESS_WHOLE_ORG_LABEL");
      }
    },

    navigateToLists() {
      this.$amplitude.track({ event_type: "Navigate to lists (top link)" });

      this.$router.push("/my-estaid/lists");
    },
  },
};
