
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import { sortByAddress as by_address_import } from "~/helpers/sort-helpers.js";

import ModalListEntity from "./ModalListEntity.vue";
import TableEntityItem from "./TableEntityItem.vue";

export default {
  layout: "blank",

  components: {
    ModalListEntity,
    TableEntityItem,
  },

  inheritAttrs: false,

  data() {
    return {
      modalOpen: false,
      selectedId: null,
    };
  },

  computed: {
    listData() {
      return this.$store.getters["lists/getListEntities"];
    },

    emptyState() {
      return this.listData.length === 0;
    },
  },

  methods: {
    getAddress(item) {
      return getPropertyAddress(item.property);
    },

    openModal(id) {
      this.selectedId = id;
      this.modalOpen = true;
    },

    byStatus(a, b) {
      return b.status?.name.localeCompare(a.status?.name);
    },

    byAddress(a, b) {
      return by_address_import({ address: this.getAddress(a) }, { address: this.getAddress(b) });
    },
  },
};
