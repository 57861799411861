
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      newStatusName: "",
    };
  },

  watch: {
    visible(value) {
      this.newStatusName = "";
    },
  },

  methods: {
    createStatus() {
      if (this.newStatusName !== "") {
        const newStatus = {
          name: this.newStatusName,
          listId: this.$store.getters["lists/getListId"],
        };

        this.$store.dispatch("lists/createListStatus", newStatus);

        this.$amplitude.track({ event_type: "Create state" });
      }
    },
  },
};
