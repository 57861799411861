
export default {
  name: "AddNote",

  inheritAttrs: false,

  props: {
    id: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      newNoteMessage: "",
    }
  },

  methods: {
    addNote() {
      if (this.newNoteMessage !== "") {
        this.$emit("add-note", this.newNoteMessage);
        this.newNoteMessage = "";
      }
    },

    focus(){
      this.$refs.textfield.$refs?.input.focus();
    }
  }
}
